import {
  CONFIRM_EMAIL_MUTATION,
  REQUEST_PASSWORD_RESET_MUTATION,
  RESET_PASSWORD_MUTATION,
  SIGN_IN_MUTATION,
  SIGN_OUT_MUTATION,
  SIGN_UP_MUTATION,
} from '~/graphql'

export const useAuthStore = defineStore('auth', () => {
  const accessTokenStore = useState('access-token')
  const auth = useCookie('auth')
  const router = useRouter()
  const currentUser = useCurrentUserStore()
  const { executeMutation } = useGraphqlApi()

  const isAuthenticated = computed(() => !!auth.value)

  const setAccessToken = (token: string | null) => {
    accessTokenStore.value = token
  }

  const signIn = async (credentials: GqlAuthProviderSignInInput) => {
    const result = await executeMutation(SIGN_IN_MUTATION, { authProvider: { credentials } })

    if (result.success) setAccessToken(result.value!.signIn.accessToken)

    return result
  }

  const signUp = async (credentials: GqlAuthProviderSignUpInput) => {
    const result = await executeMutation(SIGN_UP_MUTATION, { authProvider: { credentials } })

    if (result.success) setAccessToken(result.value!.signUp.accessToken)

    return result
  }

  const signOut = async () => {
    await executeMutation(SIGN_OUT_MUTATION, {})
    await router.push('/')

    setAccessToken(null)

    auth.value = null

    currentUser.clear()
  }

  const confirmEmail = (input: GqlConfirmEmailInput) => executeMutation(CONFIRM_EMAIL_MUTATION, input)

  const requestPasswordReset = (input: GqlRequestPasswordResetInput) => executeMutation(REQUEST_PASSWORD_RESET_MUTATION, input)

  const resetPassword = (input: GqlResetPasswordInput) => executeMutation(RESET_PASSWORD_MUTATION, input)

  return {
    confirmEmail,
    isAuthenticated,
    requestPasswordReset,
    resetPassword,
    signIn,
    signOut,
    signUp,
  }
})
