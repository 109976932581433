import {
  ADD_USER_LOCATION_MUTATION,
  ADD_USER_SOCIAL_NETWORK_MUTATION,
  CHANGE_PASSWORD_MUTATION,
  CHANGE_USERNAME_MUTATION,
  COUNTRIES_QUERY,
  CURRENT_USER_QUERY,
  DELETE_SPECIALTY_MUTATION,
  DELETE_USER_AVATAR_MUTATION,
  DELETE_USER_COVER_MUTATION,
  DELETE_USER_LOCATION_MUTATION,
  DELETE_USER_SOCIAL_NETWORK_MUTATION,
  DELETE_WORK_IMAGE_MUTATION,
  LOCALITIES_QUERY,
  SET_USER_LOCATION_AS_MAIN_MUTATION,
  SUBDIVISIONS_QUERY,
  TOGGLE_LOCATION_VISIBILITY_MUTATION,
  TOGGLE_SPECIALTY_ACTIVE_MUTATION,
  UPDATE_LOCALE_MUTATION,
  UPDATE_PHOTO_MODEL_SPECIALTY_MUTATION,
  UPDATE_PHOTOGRAPHER_SPECIALTY_MUTATION,
  UPDATE_PROFILE_MUTATION,
} from '~/graphql'

type LocationEntry = {
  code: string
  name: string
}

export const useCurrentUserStore = defineStore('currentUser', () => {
  const authStore = useAuthStore()
  const { executeQuery, executeMutation } = useGraphqlApi()

  const me = ref<GqlUser>()
  const countries = ref<{ value: string, title: string }[]>([])
  const subdivisions = ref<{ value: string, title: string }[]>([])
  const localities = ref<{ value: string, title: string }[]>([])

  const mapLocations = (items: LocationEntry[]) => items.map(({ code, name }) => ({ value: code, title: name }))

  const fetch = async () => {
    if (!authStore.isAuthenticated) return null

    const result = await executeQuery(CURRENT_USER_QUERY)

    me.value = result.success ? result.value?.me : {}

    return me.value
  }

  const clear = () => me.value = undefined

  const fetchCountries = async () => {
    const result = await executeQuery(COUNTRIES_QUERY)

    countries.value = result.success ? mapLocations(result.value?.countries) : []
  }

  const fetchSubdivisions = async (country: string) => {
    const result = await executeQuery(SUBDIVISIONS_QUERY, { country })

    subdivisions.value = result.success ? mapLocations(result.value?.subdivisions) : []
  }

  const fetchLocalities = async (country: string, subdivision: string) => {
    const result = await executeQuery(LOCALITIES_QUERY, { country, subdivision })

    localities.value = result.success ? mapLocations(result.value?.localities) : []
  }

  const clearLocationsData = () => {
    countries.value = []
    subdivisions.value = []
    localities.value = []
  }

  const updateLocale = async (locale: GqlLocaleEnum) => {
    const result = await executeMutation(UPDATE_LOCALE_MUTATION, { locale })

    if (result.failure) return

    return result.value
  }

  const updateProfile = async (input: GqlUpdateProfileInput) => {
    const result = await executeMutation(UPDATE_PROFILE_MUTATION, input)

    await fetch()

    return result
  }

  const addLocation = async (input: GqlAddUserLocationInput) => {
    const result = await executeMutation(ADD_USER_LOCATION_MUTATION, input)

    await fetch()

    return result
  }

  const setLocationAsMain = async (id: string) => {
    await executeMutation(SET_USER_LOCATION_AS_MAIN_MUTATION, { id })
    await fetch()
  }

  const toggleLocationVisibility = async (id: string) => {
    await executeMutation(TOGGLE_LOCATION_VISIBILITY_MUTATION, { id })
    await fetch()
  }

  const deleteLocation = async (id: string) => {
    const result = await executeMutation(DELETE_USER_LOCATION_MUTATION, { id })

    await fetch()

    return result
  }

  const deleteAvatar = async () => {
    const result = await executeMutation(DELETE_USER_AVATAR_MUTATION, {})

    await fetch()

    return result
  }

  const deleteCover = async () => {
    const result = await executeMutation(DELETE_USER_COVER_MUTATION, {})

    await fetch()

    return result
  }

  const changePassword = (input: GqlChangePasswordInput) => executeMutation(CHANGE_PASSWORD_MUTATION, input)

  const changeUsername = async (input: GqlChangeUsernameInput) => {
    const result = await executeMutation(CHANGE_USERNAME_MUTATION, input)

    await fetch()

    return result
  }

  const addSocialNetwork = async (input: GqlAddUserSocialNetworkInput) => {
    const result = await executeMutation(ADD_USER_SOCIAL_NETWORK_MUTATION, input)

    await fetch()

    return result
  }

  const deleteSocialNetwork = async (id: string) => {
    const result = await executeMutation(DELETE_USER_SOCIAL_NETWORK_MUTATION, { id })

    await fetch()

    return result
  }

  const toggleSpecialtyActive = async (specialty: GqlUserSpecialtyEnum) => {
    await executeMutation(TOGGLE_SPECIALTY_ACTIVE_MUTATION, { specialty })
    await fetch()
  }

  const deleteSpecialty = async (specialty: GqlUserSpecialtyEnum) => {
    const result = await executeMutation(DELETE_SPECIALTY_MUTATION, { specialty })

    await fetch()

    return result
  }

  const updatePhotographerSpecialty = async (input: GqlUpdatePhotographerSpecialtyInput) => {
    const result = await executeMutation(UPDATE_PHOTOGRAPHER_SPECIALTY_MUTATION, input)

    await fetch()

    return result
  }

  const updatePhotoModelSpecialty = async (input: GqlUpdatePhotoModelSpecialtyInput) => {
    const result = await executeMutation(UPDATE_PHOTO_MODEL_SPECIALTY_MUTATION, input)

    await fetch()

    return result
  }

  const deleteWork = async (id: string) => {
    const result = await executeMutation(DELETE_WORK_IMAGE_MUTATION, { id })
    await fetch()

    return result
  }

  return {
    addLocation,
    addSocialNetwork,
    changePassword,
    changeUsername,
    clear,
    clearLocationsData,
    countries,
    deleteAvatar,
    deleteCover,
    deleteLocation,
    deleteSocialNetwork,
    deleteSpecialty,
    deleteWork,
    fetch,
    fetchCountries,
    fetchLocalities,
    fetchSubdivisions,
    localities,
    me,
    setLocationAsMain,
    subdivisions,
    toggleLocationVisibility,
    toggleSpecialtyActive,
    updateLocale,
    updatePhotographerSpecialty,
    updatePhotoModelSpecialty,
    updateProfile,
  }
})
